    .title {
        text-align: center;
        margin-top: 5%;
        margin-bottom: 3%;

        h1 {
            color: var(--secondary, #262626);
            font-size: 38px;
            font-family: Montserrat;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            text-transform: uppercase;
        }
    }

    .gallery {
        img {
            width: 100%;
            height: 100%;
        }
    }


    @media (max-width: 480px) {
        .title {
            text-align: left;

            h1 {
                font-size: 20px;
            }
        }

        .container {
            width: 93%;
            margin: 0 auto;
        }
    }