@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
  margin: 0;
  pading: 0;
  background: #F6F7F8;
}

*{
  margin: 0;
  pading: 0;
}

.react-viewer-canvas {
  background: #FFF;
}

h1, h2, h3,h4,h5,h6{
  color: var(--secondary, #262626);
  font-style: normal;
  line-height: normal;  
  font-family: Montserrat;
  
}

h1{
  font-weight: 600; 
}
p{
  color: var(--secondary, #262626);
  font-size: 14px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
}


code {
}

a{
  color: var(--secondary, #262626);
  font-size: 12px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration: none;
}