.container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1300px;
    margin: 0 auto;

    img {
        width: 100%;
        height: auto;
        object-fit: cover;
        object-position: center;
    }

}

@media (max-width: 480px) {
    .container {
        flex-direction: column-reverse;
    }
}