.root {
    margin-right: 24px;
    box-sizing: border-box;
}

.viewer {
    position: relative !important;
    z-index: 999999 !important;
    background-color: #FFF;
    background: #fff;
}

.container {
    background: #FFF;
    padding: 5%;
    width: 384px;
    box-sizing: border-box;
    img {
        max-width: 100%;
        height: 297px;
        object-fit: contain;
    }
}

.title{
    margin-top: 2%;
}

@media only screen and (min-width: 320px) and (max-width: 550px) {
    .container{
        max-width: 280px;
    }
}

.react-viewer-canvas {
    background: #FFF;
}