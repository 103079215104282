.footer {
    margin-top: 5%;
    background: #262626;
    padding: 1% 0;
}

.containerFooter {
    max-width: 1200px;
    margin: 0 auto;
}



.title {
    margin-top: 3%;
    margin-bottom: 3%;

    h1 {
        color: #FFF;
        text-align: center;
        font-size: 38px;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-transform: uppercase;
        margin-bottom: 2%;
    }

    p {
        color: var(--whit, #FFF);
        text-align: center;
        font-size: 14px;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        line-height: 140%;
    }
}


.Form {
    display: grid;
    grid-template-columns: 1fr 1fr 0fr;
    gap: 20px;
}


.input {
    border-radius: 50px;
    background: #333;
    padding: 14px;
    border: none;
    color: var(--whit, #FFF);
    font-size: 12px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    line-height: 14px;
}

.input::placeholder {
    color: #9D9D9D;
    font-size: 12px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    line-height: 14px;
}


.FormContainer {
    display: flex;
    justify-content: center;
}

.button {
    border-radius: 50px;
    background: var(--primery, #FFC527);
    box-shadow: 0px 2px 10px 0px rgba(255, 197, 39, 0.40);
    padding: 2% 11px;
    border: none;
    color: var(--whit, #FFF);
    font-size: 12px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    line-height: 14px;
    transition: all ease-in .3s;

    &:hover {
        background: #FFD45F;
        box-shadow: 0px 2px 10px 0px rgba(255, 197, 39, 0.40);
    }
}

.line {
    border: 1px #333333 solid;
    width: 100%;
    margin: 3% 0;
}


.icons {
    display: flex;
    justify-content: end;
    gap: 10px;
}


.contacts {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.linkContact {
    display: flex;
    justify-content: space-between;
}

.numberTelefon {
    margin-left: -100%;

    a {
        color: #9D9D9D;
        text-align: right;
        font-size: 12px;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }
}

.nameContact {
    color: var(--whit, #FFF);
    font-size: 18px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-align: end;
}

@media (max-width: 480px) {
    .title {
        margin: 10% 0;

        h1 {
            font-size: 20px;
            text-align: left;
            max-width: 328px;
        }

        p {
            font-size: 10px;
            text-align: left;
            max-width: 328px;
            margin-top: 5%;
        }
    }
   
    .nameContact{
        font-size: 12px;
    }

    .Form {
        grid-template-columns: 1fr 1fr;
        margin-bottom: 10%;
    }


    .FormContainer {
        display: block;
        justify-content: left;
    }


    .button {
        max-width: 100%;
        padding: 4%;

        &:last-of-type {
            grid-column: span 2;
        }
    }

    .containerFooter {
        max-width: 93%;
        margin: 0 auto;
    }

    .numberTelefon {
        margin-left: -10%;
        a{
            font-size: 10px;
        }
    }

    .icons{
        img{
            width: 16px;
            height: 16px;
            margin-top: 5%;
        }
    }

    .input {
        width: 85%;
    }

    .contacts {
        margin: 10% 0;
    }
}