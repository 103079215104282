.title {
    text-align: center;
    margin-top: 5%;
    margin-bottom: 3%;
  
    h1 {
      color: var(--secondary, #262626);
      font-size: 38px;
      font-family: Montserrat;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      text-transform: uppercase;
    }
  }
  
  .btnContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }
  
  .select {
    // padding: 1% 1%;
    border-radius: 50px;
    border: 1px solid var(--secondary, #262626);
    background: none;
    cursor: pointer;
    min-width: 120px;
  
    select {
      background: none;
      border: none;
      width: 100%;
      padding: 5px;
      text-align: center;
    }
  }
  
  .imageContainer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 24px;
    margin-top: 3%;
    cursor: pointer;
    min-height: 244px; /* Устанавливаем минимальную высоту */
  
    img {
      width: 100%;
      height: 100%;
      transition: transform 0.3s ease; /* Добавляем плавный переход для изображений */
      &:hover {
        transform: scale(1.05); /* Немного увеличиваем изображение при наведении */
      }
    }
  }
  
  @media (max-width: 480px) {
    .title h1 {
      text-align: left;
      font-size: 20px;
    }
  
    .btnContainer {
      justify-content: left;
    }
  
    .container {
      width: 93%;
      margin: 20% auto;
    }
  
    .imageContainer {
      grid-template-columns: 1fr 1fr;
      img {
        &:first-of-type {
          grid-column: span 2;
        }
      }
    }
  }
  