.container {
    h1 {
        font-size: 72px;
        max-width: 417px;
    }

    p {
        max-width: 417px;
        margin: 5% 0;
    }

    a {
        border-radius: 50px;
        background: var(--primery, #FFC527);
        box-shadow: 0px 2px 10px 0px rgba(255, 197, 39, 0.40);
        padding: 16px 24px;
        border: none;

        color: var(--whit, #FFF);
        font-size: 14px;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        line-height: 18px;
        transition: all ease-in .3s;

        
        &:hover {
            background: #FFD45F;
            box-shadow: 0px 2px 10px 0px rgba(255, 197, 39, 0.40);
        }
    }
}

@media (max-width: 480px) {
    .container {
        display: flex;
        flex-direction: column;
        width: 93%;
        padding-bottom: 44px;
        h1 {
            font-size: 38px;
            max-width: 417px;
        }

        p {
            max-width: 330px;
            margin: 5% 0;
            font-size: 10px;
        }

        a {
            max-width: 64px;
            max-height: 35px;
            margin-bottom: 8px;
            font-size: 12px;
        }
    }
}