.title {
  margin-top: 10%;
  margin-bottom: 2%;
  h1 {
    text-align: center;
    color: var(--secondary, #262626);
    text-align: center;
    font-size: 38px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
  }
}

@media (max-width: 480px) {
  .title {
    margin-bottom: 6%;
    h1 {
      font-size: 20px;
      text-align: left;
    }
  }
  .container {
    width: 93%;
    margin: 0 auto;
    margin-top: 10%;
  }
}

.slide {
  width: 384px !important;

  @media (max-width: 480px) {
    width: 284px !important;
  }
}
