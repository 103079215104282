.cardGrid{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;

    gap: 24px;
}

.title{
    h1{
      text-align: center;
      margin-top: 10%;
      margin-bottom: 3%;
    }
}


@media (max-width: 480px) {
    .cardGrid{
        grid-template-columns: 1fr 1fr;
        gap: 16px;
    }
    .container{
        display: none;
        width: 93%;
        margin: 0 auto;
        margin-top: 20%;
    }
    .title{
        h1{
            font-size: 20px;
            text-align: left;
        }
    }
}