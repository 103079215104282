.backgroundCard {
    padding: 5% 0;
    background: #FFF;
    display: flex;
    align-items: center;
    justify-content: center;
}


.center1 {
    display: flex;
    justify-content: center;
    align-items: center;
}

.center{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
}

@media (max-width: 480px) {
    .center{
        grid-template-columns: 1fr 1fr;
        gap: 10px;
        margin: 7% 0;
    }
}