.Mobilecontainer{
  display: none;
}

.container {
  display: grid;
  grid-template-rows: repeat(19, 47px);
  grid-template-columns: 27% 32% 46.5%;
  justify-content: space-between;
  margin-top: 8%;
}

.item1 {
  &:nth-of-type(1) {
    grid-row: span 9;
    grid-column: span 2;
  }
}

.item2 {
  &:nth-of-type(2) {
    grid-row: span 7;
  }
}

.item3 {
  &:nth-of-type(3) {
    grid-row: span 10;
  }
}

.item4 {
  &:nth-of-type(4) {
    grid-row: span 4;
  }
}

.item5 {
  &:nth-of-type(5) {
    grid-row: span 8;
    margin-top: 23%;
  }
}

.item6 {
  margin-left: 93%;
  &:nth-of-type(6) {
    grid-row: span 6;
  }
}

.item7 {
  margin-left: 100%;
  &:nth-of-type(7) {
    grid-row: span 3;
    margin-top: -18%;
  }
}

// 



@media only screen and  (min-width: 320px )  and (max-width: 550px) {
  .container {
    display: none;
    // display: grid;
    // gap: 10px;
    // grid-template-columns: 1fr 1fr;
    // width: 93%;
    // margin: 15% auto;


  }

  .Mobilecontainer{
    max-width: 93%;
    display: grid;
    justify-content: center;
    margin: 15% auto;
    grid-template-columns: 1fr;
    gap: 20px;
        img {
      width: 100%;
    }
  }

  .row{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    align-items: center;
  }

  .text{
    color: #3E3E3E;
font-family: Montserrat;
font-size: 9px;
font-style: normal;
font-weight: 500;
line-height: normal;
  }

  .infro{
    max-height: 400px;
  }
  .imgInfra{
    max-height: 400px;
  }
}