.cardStyle {
    h1 {
        color: var(--primery, #FFC527);
        text-shadow: 0px 6px 24px 0px rgba(255, 197, 39, 0.40);
        font-size: 44px;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 800;
        line-height: normal;
        text-align: center;
    }

    p {
        color: var(--secondary, #262626);
        font-size: 16px;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        text-align: center;
        width: 60%;
        margin: 10px auto;
    }
}

@media (max-width: 480px) {
    .cardStyle {
        p{
            font-size: 10px;   
        }
        h1 {
            font-size: 20px;
        }
    }
}